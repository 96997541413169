// External imports
import { Injectable } from '@angular/core';
import { isArray, map, every, isEmpty, isObject } from 'lodash';
import { ListItem } from 'carbon-components-angular';
import { BehaviorSubject, Observable } from 'rxjs';

// Global imports
import { Account } from 'assets/models/Account';
import { FollowUp } from 'assets/models/FollowUp';
import { ResponseData } from 'assets/models/ResponseData';
import { Warranty } from 'assets/models/Warranty';

// Local imports
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class AccountService extends BaseService {
	private routePrefix: string = `accounts`;

	private filter = new BehaviorSubject<any>({});
	public filterData: Observable<any> = this.filter.asObservable();
	private accounts = new BehaviorSubject<Account[]>([]);
	public acountsData: Observable<Account[]> = this.accounts.asObservable();

	public async getAll(branch?: string, isManagement?: boolean): Promise<ResponseData<Account[]>> {
		let queryParams = '';

		if (branch) {
			queryParams += `?branch=${branch}`;
		}

		if (isManagement) {
			queryParams += `${queryParams ? '&' : '?'}isManagement=${isManagement}`;
		}
		return await this.get(`${this.routePrefix}/all${queryParams}`);
	}

	public async getByName(name: string): Promise<ResponseData<Account>> {
		return await this.get(`${this.routePrefix}/getByName/${name}`);
	}

	public async getBySalesforce(salesforce: string): Promise<ResponseData<Account>> {
		return await this.get(`${this.routePrefix}/salesforce/${salesforce}`);
	}

	public async getByID(accountId: string): Promise<ResponseData<Account>> {
		return await this.get(`${this.routePrefix}/${accountId}`);
	}

	public async update(data: Partial<Account>): Promise<ResponseData<Account>> {
		return await this.patch(`${this.routePrefix}/${data._id}`, data);
	}

	public async create(data: Account): Promise<ResponseData<Account>> {
		return await this.post(`${this.routePrefix}`, data);
	}

	public async getWarranty(
		accountId: string,
		serviceTypeId: string,
		policySymbol?: string
	): Promise<ResponseData<Warranty>> {
		let queryParamString = `?serviceTypeId=${serviceTypeId}`;

		if (policySymbol) {
			queryParamString += `&policySymbol=${policySymbol}`;
		}
		return await this.get(`${this.routePrefix}/${accountId}/warranties${queryParamString}`);
	}

	public async getFollowUp(accountId: string, serviceTypeId: string): Promise<ResponseData<FollowUp>> {
		return await this.get(`${this.routePrefix}/${accountId}/followUp?serviceTypeId=${serviceTypeId}`);
	}

	// Filter the accounts by the combination of all filters available
	filterAccounts(event: ListItem, filterBy: string, filters: any, allAccounts: Account[]): void {
		filters = this.createFilters(event, filterBy, filters);
		this.filter.next(filters);
		if (isEmpty(filters)) {
			this.accounts.next(allAccounts);
			return;
		}

		const filteredAccounts = this.applyFilters(allAccounts, filters);
		this.accounts.next(filteredAccounts);
	}

	// Creates an object with the filters we want to utilize
	private createFilters(event: ListItem, filterBy: string, filters: any): any {
		filters = { ...filters };

		if (isEmpty(event) || event === undefined) {
			delete filters[filterBy];
		} else if (isArray(event) && filterBy === 'brandingConfig.branding') {
			filters[filterBy] = map(event, 'value');
		} else if (isArray(event)) {
			filters[filterBy] = map(event, 'content');
		} else if (isObject(event)) {
			filters[filterBy] = event.content.toLowerCase();
		} else {
			filters[filterBy] = event;
		}

		return filters;
	}

	// Applies filters to the list of accounts
	private applyFilters(allAccounts: Account[], filters: any): Account[] {
		return allAccounts.filter((item) =>
			every(filters, (value, key) => {
				if (key === 'name') {
					return item[key].toLowerCase().includes(value.toLowerCase());
				} else if (key === 'brandingConfig.branding') {
					return value.includes(item.brandingConfig?.branding);
				} else {
					return isArray(value) ? value.includes(item[key]) : item[key] === value;
				}
			})
		);
	}
}
