<!-- Icon to Display when configured showIcon as True -->
<div class="selected-item" *ngIf="selectedItem && showIcon">
	<img
		*ngIf="selectedItem.iconUrl !== '' && selectedItem.iconUrl !== undefined"
		class="selected-icon"
		[alt]="selectedItem.content"
		[src]="selectedItem.iconUrl"
	/>
</div>
<!-- Combo box -->
<ibm-combo-box
	[invalid]="!!invalidText"
	[invalidText]="invalidText"
	[warn]="!!warnText"
	[warnText]="warnText"
	[disabled]="disabled"
	size="md"
	[helperText]="helperText"
	[appendInline]="true"
	[type]="type"
	placeholder=""
	class="{{ className }}"
	label="{{ label }}"
	[(ngModel)]="selectedItem"
	[ngClass]="{ 'with-icon': showIcon && selectedItem }"
	(selected)="onSelectedItemClick($event)"
	(search)="search($event)"
	(click)="onDropdownClick()"
	(close)="onBlurHandler()"
>
	<ibm-dropdown-list [items]="_items" [listTpl]="dropdownRenderer"></ibm-dropdown-list>
</ibm-combo-box>

<!--Template to render icon for each option in the combobox -->
<ng-template #dropdownRenderer let-item="item">
	<div class="combobox-option">
		<img *ngIf="item.iconUrl !== '' && showIcon" class="combobox-icon" [alt]="item.content" [src]="item.iconUrl" />
		<span>{{ item.content }}</span>
	</div>
</ng-template>
