import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

/**
 * A reusable modal component that wraps the base Carbon Design System modal.
 *
 * @example
 * <button (click)="isOpen = true">Open modal</button>
 * <rb-modal [open]="isOpen">
 *		<h1>Modal title</h1>
 *		<p>Modal content goes here</p>
 *		<button (click)="isOpen = false">Close</button>
 * </rb-modal>
 */
@Component({
	selector: 'rb-modal',
	template: `
		<ibm-modal
			class="rb-modal"
			[size]="size"
			[open]="open"
			[ngClass]="{ 'bg-dark': isBackgroundDark, radius: isBorderRadius }"
			(overlaySelected)="closeModal()"
		>
			<ng-content />
		</ibm-modal>
	`,
	styles: [
		`
			.rb-modal .cds--modal-container {
				background: #ffffff;
				box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.05);
			}
			.rb-modal .cds--modal {
				background: rgba(227, 227, 227, 0.7);
				backdrop-filter: blur(2px);
			}
			.rb-modal.bg-dark .cds--modal {
				background: rgba(0, 0, 0, 0.69);
			}
			.rb-modal.radius .cds--modal-container {
				border-radius: 0.5rem;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {
	@Input() open: boolean = false;
	@Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';
	@Input() isBackgroundDark: boolean = false;
	@Input() isBorderRadius: boolean = true;
	@Input() overlaySelectedClose: boolean = true;
	@Output() closeEmitter: EventEmitter<void> = new EventEmitter<void>();

	public closeModal(): void {
		if (this.overlaySelectedClose) {
			this.open = false;
			this.closeEmitter.emit();
		}
	}
}
